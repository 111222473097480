import common from '@/common'

const staffFormMixin = {
  methods: {
    handleSubmitError(err, { callBellFacilities, forceSubmitFn }) {
      const { data, result_msg, return_code } = err.cause?.respond
      let title = common.getI18n(`error.${result_msg}`)
      let html = ''

      // list NFC tags
      if (data.errNfcs && data.errNfcs.length > 0) {
        const [typeLoginArr, typeCbArr] = [[], []]
        data.errNfcs.forEach(item => {
          // const content = `<span class="wh-font-family-monospace">${item.serialNumber.toUpperCase()}</span>`
          const content = `<span class="badge badge-light-secondary wh-font-family-monospace font-weight-normal wh-user-select-all">${item.serialNumber.toUpperCase()}</span>`
          if (item.nfcType === 'Login' && !typeLoginArr.includes(content)) typeLoginArr.push(content)
          if (item.nfcType === 'CallBell' && !typeCbArr.includes(content)) typeCbArr.push(content)
        })
        html += typeLoginArr.length > 0
          ? `<div style="margin-top:.5rem;margin-bottom:.2rem">`
            + `<span style="font-weight: bold">${common.getI18n('staff.login_nfc')}</span><br />`
            + typeLoginArr.join(` `)
            + `</div>`
          : ''
        html += typeCbArr.length > 0
          ? `<div style="margin-top:.5rem;">`
            + `<span style="font-weight: bold">${common.getI18n('staff.call_bell_nfc')}</span><br />`
            + typeCbArr.join(` `)
            + `</div>`
          : ''
      }

      if (result_msg.indexOf('NeedOverWrite') >= 0) {
        common.showConfirm({
          title, html, confirm_fun: forceSubmitFn
        })
      } else {
        html = `<div class="wh-font-family-monospace">${common.getI18n('common.error_code')}: ${return_code}</div>` + html
        common.showAlert({
          title, html,
        })
      }
    }
  }
}
export default staffFormMixin
