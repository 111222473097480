<template>
  <div>

    <validation-observer
      tag="div"
      v-for="(item, itemIndex) in vItems"
      :ref="`${slotConfig.name}_${itemIndex}`"
      :vid="`${slotConfig.name}_${itemIndex}`"
      :key="item.id"
      class="mb-05"
      #default="{ errors: voErrors }"
    >
      <!--<div>{{ voErrors }}</div>-->
      <b-row>
        <b-col cols="12" class="d-flex">
          <div class="flex-grow-1 d-flex">
            <b-input-group :id="`${slotConfig.name}_${itemIndex}_itemText`">
              <b-form-input
                type="text"
                :ref="`${slotConfig.name}_${itemIndex}_itemText`"
                :value="getItemText(itemIndex)"
                :class="slotConfig.input_class"
                style="height:100%;background-color:transparent;"
                v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                readonly/>
              <b-input-group-append>
                <b-button
                  v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                  variant="outline-primary"
                  style="height:100%;"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button
            v-if="slotConfig.show_del_btn"
            class="flex-shrink-0 ml-1"
            variant="outline-danger"
            @click="deleteItem(itemIndex)"
            :disabled="vItems.length <= slotConfig.min_count"
          >
            <!--{{ $t('common.delete') }}-->
            <feather-icon icon="MinusCircleIcon"/>
          </b-button>
        </b-col>

        <!-- 檢測當前 item 是否選擇了院舍，及填寫了 NFC -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          class="validation-provider"
          :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_exist`] && voErrors[`${slotConfig.name}_${itemIndex}_exist`].length > 0 }"
          :ref="`${slotConfig.name}_${itemIndex}_exist`"
          :vid="`${slotConfig.name}_${itemIndex}_exist`"
          :name="$t(slotConfig.label)"
          :customMessages="slotConfig.custom_messages"
          :rules="{ required: true }"
        >
          <b-col cols="12" v-show="errors.length > 0">
            <input type="hidden" :value="(
                item.value.serialNumber
                && item.value.facilityID > -1
                && facilityAndRoleList.map(f => f.facilityID).includes(item.value.facilityID)
              ) ? '_' : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!-- 檢測當前 item NFC 是否符合規則 -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          class="validation-provider"
          :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_regexp`] && voErrors[`${slotConfig.name}_${itemIndex}_regexp`].length > 0 }"
          :ref="`${slotConfig.name}_${itemIndex}_regexp`"
          :vid="`${slotConfig.name}_${itemIndex}_regexp`"
          :name="$t(slotConfig.label)"
          :customMessages="null"
          :rules="{ regex: nfcUtils.regexNfc }"
        >
          <b-col cols="12" v-show="errors.length > 0">
            <input type="hidden" :value="item.value.serialNumber ? item.value.serialNumber : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!--檢測當前 item 與其他 items 之間是否存在重複 NFC -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          class="validation-provider"
          :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_duplicateNfc`] && voErrors[`${slotConfig.name}_${itemIndex}_duplicateNfc`].length > 0 }"
          :ref="`${slotConfig.name}_${itemIndex}_duplicateNfc`"
          :vid="`${slotConfig.name}_${itemIndex}_duplicateNfc`"
          :name="$t(slotConfig.label)"
          :customMessages="{ length: slotConfig.duplicate_tips}"
          rules="length:0"
          v-if="slotConfig.check_duplicate"
        >
          <b-col cols="12" v-show="errors.length > 0">
            <input type="hidden" :value="duplicateNfcs.includes(item.value.facilityID + ' ' + item.value.serialNumber) ? '_' : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!-- 檢測當前 item 與其他 items 之間是否存在重複院舍 -->
        <!--<validation-provider-->
        <!--  tag="div"-->
        <!--  #default="{ errors }"-->
        <!--  class="validation-provider"-->
        <!--  :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_duplicateFacility`] && voErrors[`${slotConfig.name}_${itemIndex}_duplicateFacility`].length > 0 }"-->
        <!--  :ref="`${slotConfig.name}_${itemIndex}_duplicateFacility`"-->
        <!--  :vid="`${slotConfig.name}_${itemIndex}_duplicateFacility`"-->
        <!--  :name="$t(slotConfig.label)"-->
        <!--  :customMessages="{ length: '重複的 院舍' }"-->
        <!--  rules="length:0"-->
        <!--  v-if="slotConfig.check_duplicate"-->
        <!--&gt;-->
        <!--  <b-col cols="12" v-show="errors.length > 0">-->
        <!--    <input type="hidden" :value="duplicateFacilities.includes(vItems[itemIndex].value.facilityID) ? '_' : ''"/>-->
        <!--    <small class="text-danger">{{ errors[0] }}</small>-->
        <!--  </b-col>-->
        <!--</validation-provider>-->

      </b-row>


      <b-modal
        :id="`${slotConfig.name}_${itemIndex}_modal`"
        :title="`${$t('common.edit')} - ${$t(slotConfig.label)}`"
        size="lg"
        :ok-title="$t('common.confirm')"
        centered no-fade no-close-on-backdrop ok-only static
        hide-header-close no-close-on-esc
        @hide="onHideModal(itemIndex)"
      >
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_facility`">
              {{ $t('common.facility') }} <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <v-select
              label="text"
              v-model="item.value.facilityID"
              :id="`${slotConfig.name}_${itemIndex}_facility`"
              :options="facilityOptions"
              :selectable="(option) => !option.disabled"
              :reduce="text => text.value"
              :clearable="false"
              :searchable="false"
              @input="onModelTagChange(itemIndex, arguments[0])"
            >
              <template #no-options>{{ $t('common.no_options') }}</template>
            </v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_sn`">
              {{ $t('common.nfc_tag') }} <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_sn`"
              v-model="item.value.serialNumber"
              @blur="() => onSnBlur(itemIndex)"
              type="text"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_isLoginNfc`">
              {{ $t('staff.use_as_login_nfc') }}
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9" style="display: flex; align-items: center; height: 38px">
            <b-form-checkbox
              name="check-button" switch inline
              :disabled="!nfcUtils.regexNfc.test(item.value.serialNumber) || !nfcUtils.getConvertedNfc(item.value.serialNumber)"
              :checked="nfcUtils.regexNfc.test(item.value.serialNumber) && getIsLoginNfc(item.value.serialNumber)"
              @input="(isLoginNfc) => onUpdateIsLoginNfc(isLoginNfc, item.value.serialNumber)"
            ></b-form-checkbox>
          </b-col>
        </b-row>
      </b-modal>

    </validation-observer>

    <b-button
      v-if="slotConfig.show_add_btn" variant="outline-primary" @click="addItem()"
      :disabled="vItems.length >= slotConfig.max_count"
    >
      <!--{{ $t('common.add') }}-->
      <feather-icon icon="PlusCircleIcon"/>
    </b-button>

  </div>
</template>

<script>
import common from '@/common'
import _ from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/utils/validations/validations'
import { getDuplicateItems, truncateString } from '@/libs/ez-utils'
import nfcUtils from "@/views/staff/nfcUtils"

export default {
  name: 'FieldModalCallBellNfcs',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    isEdit: {
      type: Boolean, default: false,
    },
    id: {
      type: String, default: '',
    },
    rowData: {
      type: Object,
    },
    input_class: {
      type: String, default: '',
    },
    items: {
      type: Array, default: () => [],
    },
    rootItem: {
      type: Object, default: () => ({}),
    },
    facilityAndRoleList: {
      type: Array, default: () => [],
    },
  },
  data() {
    return {
      console, setTimeout, nfcUtils,
      autoIndex: 0,
      vItems: [], // 存放 QRCode 對象
      vItemDefaultValue: { // modelItem 的空值
        serialNumber: '',
        facilityID: undefined,
      },
      slotConfig: Object.assign({
        // type: 'text',
        name: 'defaultName',
        label: 'defaultLabel',
        min_count: this.rootItem.rule?.split('|')
          .includes('required') ? 1 : 0,
        max_count: 1,
        check_duplicate: true,
        duplicate_tips: this.$t('common.duplicate_content_tips'),
        show_del_btn: false,
        show_add_btn: false,
      }, this.rootItem.slot_config),
    }
  },

  computed: {
    facilityOptions: {
      get() {
        return common.getSelectOptions(this.facilityAndRoleList, 'facilityName', 'facilityID')
          .map(option => ({
            ...option,
            disabled: // 控制是否可選
              !this.rowData.callBellFacilitys?.includes(option.value)
              || !this.rowData.facilityRoleIDs?.map(obj => obj.facilityID).includes(option.value),
          }))
      }
    },
    duplicateNfcs: {
      get() {
        return this.slotConfig.check_duplicate
          ? getDuplicateItems(this.vItems.map(item => item.value.facilityID + ' ' + item.value.serialNumber))
          : []
      },
    },
    duplicateFacilities: {
      get() {
        return this.slotConfig.check_duplicate
          ? getDuplicateItems(this.vItems.map(item => item.value.facilityID))
          : []
      },
    },
  },

  watch: {
    items: {
      handler(items, _oldValues) {
        // 當 items 的值發生變化時，會先刪除多餘的 modelItems，再新增缺少的 modelItems
        for (const [i, item] of items.entries()) {
          while (this.vItems.length >= i + 1 && !_.isEqual(item, this.vItems[i].value)) {
            this.deleteItem(i, false)
          }
          if (this.vItems.length < i + 1) {
            this.addItem(_.clone(item))
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    addItem(value, isUpdate = true) {
      this.vItems.push({
        value: value === undefined ? _.clone(this.vItemDefaultValue) : value,
        id: this.autoIndex,
      })
      this.autoIndex += 1
      isUpdate && this.updateValues()
    },
    deleteItem(itemIndex, isUpdate = true) {
      this.vItems.splice(itemIndex, 1)
      isUpdate && this.updateValues()
    },
    onModelTagChange(itemIndex, modelTag) {
      // console.log(itemIndex, this.vItems[itemIndex].value)
      this.updateValues(true)
    },
    updateValues(validate = false) {
      if (validate) {
        const refs = this.$refs
        const conf = this.slotConfig
        setTimeout(() => {
          this.vItems.forEach((v, i) => {
            refs[`${conf.name}_${i}_exist`][0].validate()
            refs[`${conf.name}_${i}_regexp`][0].validate()
            if (conf.check_duplicate) refs[`${conf.name}_${i}_duplicateNfc`][0].validate()
          })
        }, 50) // to delay is to fix a UX bug
      }

      this.$emit('updateValues', _.cloneDeep(this.vItems.map(item => item.value)))
    },
    onSnBlur(itemIndex) {
      const snRaw = this.vItems[itemIndex].value.serialNumber
      if (!nfcUtils.regexNfc.test(snRaw)) return this.updateValues(true)

      const snConverted = nfcUtils.getConvertedNfc(snRaw)
      if (snRaw === snConverted) return this.updateValues(true)

      this.vItems[itemIndex].value.serialNumber = snConverted
      common.showToast({
        title: common.getI18n('common.form_field_converted_tip'),
        variant: 'info',
      })

      this.updateValues(true)
    },
    getItemText(itemIndex) {
      const vItem = this.vItems[itemIndex]
      const serialNumber = vItem.value.serialNumber
      const facilityName = this.facilityAndRoleList.find(f => f.facilityID === vItem.value.facilityID)?.facilityName ?? ''
      return (facilityName || serialNumber)
        ? truncateString(serialNumber ?? '', 16, 5).padEnd(16, ' ') + ' | ' + facilityName
        : ''
    },
    getIsLoginNfc(snRaw) {
      const snConverted = nfcUtils.getConvertedNfc(snRaw)
      return snConverted && this.rowData.loginNfcs.filter(n => !!n).includes(snConverted)
    },
    onUpdateIsLoginNfc(isLoginNfc, snRaw) {
      if (!nfcUtils.regexNfc.test(snRaw)) return false

      const snConverted = nfcUtils.getConvertedNfc(snRaw)
      if (!snConverted) return false

      this.$emit('onUpdateIsLoginNfc', isLoginNfc, snConverted)
    },
    onHideModal(itemIndex) {
      // fix a UI bug
      setTimeout(() => this.$refs[`${this.slotConfig.name}_${itemIndex}_itemText`][0].$el.blur(), 50)
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.field-row-label {
  padding: calc(0.438rem + 1px) 0;
}

// 只显示第一个 .error
.validation-provider {
  display: none;

  &.error {
    display: block;
  }

  &.error ~ &.error {
    display: none;
  }
}

.modal-body {
  .row {
    margin-bottom: 1rem;
  }
  .row:last-of-type {
    margin-bottom: 0;
  }
}
</style>
